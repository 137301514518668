<script>
import BgIntro from "@/assets/img/bg-intro.png";
import bg5 from "@/assets/img/bg5.jpg";

import { MyComponent } from "@/components/MyComponent.js";
import TimePicki from "@/components/timepicki.vue";
import SpaceLocationMixin from "@/mixins/SpaceLocation.js";
import moment from "moment";

require("jquery-ui/ui/widgets/datepicker.js");
require("jquery-ui/themes/base/all.css");

export default {
  name: "rehearsal-spaces",
  mixins: [MyComponent],
  components: { TimePicki },
  data: function () {
    return {
      time_options: [],
      bgImage: { "background-image": `url(${BgIntro})` },

      rehearsal_date: SpaceLocationMixin.getTodaysDate(),
      start_time_digits: null,
      start_time_am_pm: "am",
      end_time_digits: null,
      end_time_am_pm: "am",
    };
  },
  methods: {
    getDefaultProduct(space) {
      return SpaceLocationMixin.getDefaultProduct(space);
    },
    setStartTime(data) {
      var d = data;
      this.start_time_digits = d.HH + ":" + d.mm;
      this.start_time_am_pm = d.a;
    },
    setEndTime(data) {
      var d = data;
      this.end_time_digits = d.HH + ":" + d.mm;
      this.end_time_am_pm = d.a;
    },
    search() {
      var query = {
        rd: this.rehearsal_date,
        start_dgts: this.start_time_digits,
        start_ampm: this.start_time_am_pm,
        end_dgts: this.end_time_digits,
        end_ampm: this.end_time_am_pm,
      };
      var msg = null;
      if (!this.rehearsal_date) {
        msg = "Date required.";
      }
      if (!this.start_time_digits || !this.end_time_digits) {
        msg = "Time required.";
      }
      if (!this.start_time_am_pm || !this.end_time_am_pm) {
        msg = "AM/PM required for each time.";
      }

      if (msg != null) {
        alert(msg);
        return;
      }
      this.$router.push({ name: "SearchSlotResults", query: query });
    },
    getHomePageImage(space) {
      if (space && space.homepage_image) {
        return "/media/" + space.homepage_image.pathname;
      }
      return bg5;
    },
    isNonRepetitiveName: function (space) {
      var name1 = space.name;
      var name2 = "";
      if (space.space_company) {
        name2 = space.space_company.company_name;
      }
      if (name1 === name2) {
        return false;
      }
      return true;
    },
    get24HourTime: function (time) {
      var timeString = "am";
      if (time > 12) {
        timeString = "pm";
        time = time % 12;
      }
      return time + timeString;
    },
  },
  watch: {
    rehearsal_date() {
      /// change query param
      const url = new URL(window.location);
      url.searchParams.set("searchDate", this.rehearsal_date);
      window.history.pushState({}, "", url);
    },
    start_time_digits() {
      const url = new URL(window.location);
      url.searchParams.set("sTd", this.start_time_digits);
      window.history.pushState({}, "", url);
    },
    start_time_am_pm() {
      const url = new URL(window.location);
      url.searchParams.set("sTap", this.start_time_am_pm);
      window.history.pushState({}, "", url);
    },
  },
  computed: {
    showHiddenSpaces() {
      if (this.$route.query.showHiddenSpaces === "1") {
        return true;
      }
      return false;
    },
    allSpaces() {
      const spaces = this.$store.state.General.spaces;
      return spaces.filter((item) => {
        if (!this.showHiddenSpaces) {
          return !item.is_hidden;
        }
        return true;
      });
    },
    rehearsal_date_readable() {
      return moment(this.rehearsal_date, "YYYY-MM-DD").format("dddd, Do MMM Y");
    },
    canSearch() {
      if (!this.rehearsal_date) {
        return false;
      }
      if (!this.start_time_digits || !this.end_time_digits) {
        return false;
      }
      if (!this.start_time_am_pm || !this.end_time_am_pm) {
        return false;
      }
      return true;
    },
  },
  created() {
    const searchDate = this.$route.query.searchDate;
    if (searchDate) {
      this.rehearsal_date = searchDate;
    }
  },
};
</script>

<template>
  <div id="Spaces">
    <section id="intro" class="section-intro text-center" :style="bgImage">
      <div class="overlay">
        <div class="container" id="cont">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="intro-title">
                Find your <span style="color: #4caf50">Tizi</span> space.
              </h1>
              <p class="sub-title">Bookings simplified.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="categories-homepage">
      <div class="container">
        <div class="row justify-content-center">
          <section
            class="col-12 col-md-8 offset-md-1 order-2"
            data-wow-delay="0.5"
          >
            <div class="container">
              <div class="row">
                <div class="col-md-12 text-center">
                  <h3 class="section-title fw-bold">SPACES</h3>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="row justify-content-center">
                    <div
                      v-for="space in allSpaces"
                      class="space col-md-6 col-12"
                      data-wow-delay="0.5"
                      :key="space.id"
                    >
                      <div class="">
                        <router-link
                          :to="{
                            name: 'Space-Sections',
                            params: { slug: space.slug },
                          }"
                          class="link align-items-end row inner"
                          :style="{
                            'background-image':
                              'url(' + getHomePageImage(space) + ')',
                          }"
                        >
                          <div class="col space-bg">
                            <div class="row align-items-center">
                              <div class="col">
                                <i class="fas fa-bacon mb-3"></i> <br />
                                <div class="space-title">
                                  {{ space.name }}
                                </div>
                                <div
                                  v-if="
                                    space.name !==
                                    space.space_company.company_name
                                  "
                                >
                                  {{ space.space_company.company_name }}
                                </div>
                              </div>
                            </div>

                            <div class="row align-items-center row2">
                              <div class="details col">
                                <div class="row" v-if="space.location">
                                  <div class="col address">
                                    <i class="fas fa-map-marker-alt"></i>
                                    {{ space.location }}
                                  </div>
                                </div>

                                <div
                                  class="row"
                                  v-if="getDefaultProduct(space)"
                                >
                                  <div class="col price">
                                    <span class="item-sub-price">
                                      <i class="fas fa-money-bill-wave-alt"></i>
                                      {{
                                        getDefaultProduct(space).hourly_rate
                                      }}/= per hour
                                    </span>
                                  </div>
                                </div>

                                <div class="row" v-if="space.type">
                                  <div class="col">
                                    <div class="type_name">
                                      {{ space.type.name }}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-2 text-center align-items-center">
                                <i class="fas fa-chevron-right"></i>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="search"
            class="col-11 col-sm-8 col-md-3 order-1"
            data-wow-delay="0.5"
          >
            <h3 class="text-center section-title">SEARCH</h3>
            <div class="row">
              <div class="container">
                <div class="col">
                  <form submit.prevent="search">
                    <div class="form-group">
                      <label for="exampleInputEmail1"
                        >Available For Date?</label
                      >
                      <div class="form-group input-group py-1">
                        <div class="input-group-prepend p-1">
                          <span class="input-group-text">
                            <i class="fa fa-calendar-day"></i>
                          </span>
                        </div>
                        <input
                          type="date"
                          class="form-control dateChoice"
                          id="date_input"
                          v-model="rehearsal_date"
                        />
                      </div>

                      <div class="row">
                        <div class="col-12 text-primary">
                          {{ rehearsal_date_readable }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="start-time-input">Start Time</label>
                      <div class="py-1">
                        <div class="form-group input-group">
                          <div class="input-group-prepend p-1">
                            <span class="input-group-text">
                              <i class="fa fa-clock"></i>
                            </span>
                          </div>
                          <time-picki
                            id="time1"
                            :end="this.end_time_digits"
                            :show_30s="false"
                            @change="setStartTime"
                          ></time-picki>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="end-time-input">End Time</label>
                      <div class="py-1">
                        <div class="form-group input-group">
                          <div class="input-group-prepend p-1">
                            <span class="input-group-text">
                              <i class="fa fa-clock"></i>
                            </span>
                          </div>
                          <time-picki
                            id="time2"
                            :start="this.start_time_digits"
                            :show_30s="false"
                            @change="setEndTime"
                          ></time-picki>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col">
                        <a
                          href="#"
                          id="successBtn"
                          class="col btn"
                          :class="{ disabled: !canSearch }"
                          @click.prevent="search"
                          >Search</a
                        >
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-group .input-group {
  margin-bottom: 3px;
}
.timepicker_wrap {
  min-width: 270px;
  width: 270px;
}
#successBtn,
.input-group-text {
  background: linear-gradient(90deg, #4caf50, #009d6b);
  &,
  a {
    color: white;
  }
}
#reach-me {
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(241, 241, 241);
  margin-top: 30px;
  margin-bottom: -25px;
}
#categories-homepage {
  background: #f6f6f6;
  padding-top: 10px;
  padding-bottom: 10px;
}
.type_name {
  display: inline-block;
  padding: 2px 5px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
}
#Spaces {
  .section-title {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .space {
    position: relative;
    font-size: 18px;

    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    margin-bottom: 10px;

    $inner_width: 300px;
    $inner_height: 250px;
    .space-inner {
      border: 1px solid #eee;
      background-color: white;
    }
    .space-bg {
      background: rgba(255, 255, 255, 0.6);
      height: 100%;
    }
    .row2 {
      margin-top: 5px;
      font-size: 18px;
    }
    .inner {
      background-position: 50% 50%;
      background-size: cover;
      width: $inner_width;
      height: $inner_height;
      margin: auto;
      border: 1px solid #333;
      border-radius: 3px;
      &:hover {
        & > div {
          padding-top: 20px;
          padding-bottom: 20px;
          transition: all 0.3s ease;
        }
      }
      & > div {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      &,
      & > div {
        border-radius: 15px;
      }
    }

    a {
      color: black;
      text-decoration: none;
    }
    .address {
      height: 20px;
      overflow: hidden;
    }

    .space-title {
      font-size: 24px;
      margin-top: 50px;
    }
    .icon-image {
      max-height: 90px;
    }
    .details {
      font-size: 14px;
      & > div {
        margin-bottom: 5px;
      }
    }
  }

  .info div {
    margin-bottom: 10px;
  }
  #intro {
    color: white;
    background-size: cover;
    background-position: 50% 50%;

    border-bottom: 1px solid rgb(241, 241, 241);
    margin-bottom: 30px;
    .container {
      padding-top: 100px;
      padding-bottom: 70px;
    }
    .intro-title {
      text-transform: uppercase;
      font-size: 50px;
      font-weight: 300;
    }
  }
  .bottom {
    border-top: 1px solid rgb(229, 229, 229);
    color: #999;
    background: white;
  }

  #search {
    .container {
      border: 1px solid black;
      background: #fcfcfc;
      border-radius: 13px;
      &:hover {
        transition: all 0.4s ease;
        background: white;
      }
    }
    form {
      padding-top: 10px;
      padding-bottom: 10px;
      & > div {
        margin-bottom: 10px;
      }
    }
  }
  .dateChoice {
    font-size: 14px;
  }
}
</style>
